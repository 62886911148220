<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>待加入</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">

            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="姓名">
                                <el-input v-model="search.user_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="昵称">
                                <el-input v-model="search.user_nick"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="手机号">
                                <el-input v-model="search.phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="身份证号">
                                <el-input v-model="search.identity_card"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="城市">
                                <el-input v-model="search.city"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                        <el-button icon="el-icon-plus" size="medium" type="primary"
                            @click="() => { this.qrcodePage = true }">加入二维码
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table v-loading="loading" :data="tableData" border size="medium">
                <el-table-column prop="user_nick" label="昵称">
                </el-table-column>
                <el-table-column prop="user_name" label="姓名">
                </el-table-column>
                <el-table-column prop="portrait" label="头像照片" width="80">
                    <template slot-scope="scope">
                        <el-avatar shape="square" :size="22" :src="scope.row.portrait"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="avatar_url" label="微信头像" width="80">
                    <template slot-scope="scope">
                        <el-avatar shape="square" :size="22" :src="scope.row.avatar_url"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="手机号" width="200">
                </el-table-column>
                <el-table-column prop="gender" label="性别" width="160">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.gender === 0" type="info">未知</el-tag>
                        <el-tag size="mini" v-if="scope.row.gender === 1">男</el-tag>
                        <el-tag size="mini" v-if="scope.row.gender === 2" type="danger">女</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="identity_card" label="身份证号" width="100">
                </el-table-column>
                <el-table-column prop="years" label="教学年限" width="130">
                </el-table-column>
                <el-table-column prop="city" label="城市" width="130">
                </el-table-column>
                <el-table-column prop="state" label="教练状态" width="100">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state === 1">正常</el-tag>
                        <el-tag size="mini" v-if="scope.row.state === 2" type="danger">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="state2" label="教练加入状态" width="120">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.state2 === 0" type="warning">审核中</el-tag>
                        <el-tag size="mini" v-if="scope.row.state2 === 1">正常</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="注册时间" width="150">
                </el-table-column>
                <el-table-column prop="update_time" label="更新时间" width="150">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="240">
                    <template slot-scope="scope">
                        <!--                        <el-button-->

                        <!--                                @click="tosee(scope.row.lesson_uuid)"-->
                        <!--                                size="mini">查看-->
                        <!--                        </el-button>-->
                        <el-button v-if="is_auth('coach.joinapply.examine') && scope.row.state2 === 0"
                            @click="operation_tip(scope.row.coach_uuid, scope.row.user_name, 'examine', 1)" type="success"
                            size="mini">通过
                        </el-button>

                        <el-button v-if="is_auth('coach.joinapply.examine') && scope.row.state2 === 0"
                            @click="operation_tip(scope.row.coach_uuid, scope.row.user_name, 'examine', 3)" type="danger"
                            size="mini">不通过
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>
        </div>
        <!--加入二维码-->
        <el-dialog :title="qrcodePage_title" :visible.sync="qrcodePage" width="450px" :close-on-press-escape="false"
            :close-on-click-modal="false">
            <el-image style="width: 100%; height: 100%" :src="qrCode"></el-image>
        </el-dialog>
    </div>
</template>
<script>
import QRCode from 'qrcode'

export default {
    data() {
        return {
            page_name: '待加入',
            issearch: true,
            loading: true,
            search: {},         // 搜索条件
            tableData: [],      // 列表内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            qrcodePage: false,
            qrcodePage_title: '扫码加入公司',
            qrCode: '',
        }
    },
    // 创建
    created() {
        this.init()
        // 生成二维码
        let userinfo = this.Tool.get_l_cache('userinfo')
        let qrCode = JSON.stringify({
            company_name: userinfo.company_name,
            company_uuid: userinfo.company_uuid,
        })
        this.qrcodePage_title = '扫码加入' + userinfo.company_name
        QRCode.toDataURL(qrCode, { margin: 0 }, (err, url) => {
            if (err) {
                return
            }
            this.qrCode = url
        })
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                user_name: '',
                user_nick: '',
                phone: '',
                identity_card: '',
                city: '',
            }
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "coach.joinapply.getlist",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(coach_uuid = '', user_name = '', operation = '', state = '') {

            let tip = ''
            let options = {}
            // 通过
            if (state === 1) {
                tip = '审批通过【' + user_name + '】？'
            }
            // 不通过
            if (state === 3) {
                tip = '审批不通过【' + user_name + '】？'
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                .then(() => {
                    this.isoperation(coach_uuid, operation, state)
                })
                .catch(() => {
                });
        },
        // 操作
        isoperation(coach_uuid = '', operation = '', state) {
            let postdata = {
                api_name: "coach.joinapply." + operation,
                token: this.Tool.get_l_cache('token'),
                coach_uuid,
                state,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*表格不换行*/
>>>.el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}
</style>
